







































































import { Vue, Component, Prop } from "vue-property-decorator";
import { Crop, CropVariety } from "@/store/models/cropVariety";
import { CropVarietyStore } from "@/store/modules";
import DynamicField from "@/views/Admin/DynamicField.vue";

@Component({
  components: {
    DynamicField
  },
  metaInfo: {
    title: "Crop Variety List",
    meta: [
      { name: "keyword", content: "Sqcc crop variety list" },
      {
        name: "description",
        content: "List of crop variety of SQCC",
        vmid: "cropVarietyList"
      }
    ]
  }
})
export default class SCMList extends Vue {
  searchInput: string = "";
  cropId: number = 0;
  cropvariety = {} as CropVariety;
  selectedIndex = -1;
  dialogFormVisible: boolean = false;
  loading: boolean = false;
  pageNum: number = 1;
  getLength(data: any) {
    if (data !== undefined) {
      return data.length;
    }
  }

  indexMethod(index: number) {
    let i = (this.varieties.page - 1) * this.varieties.page_size + index + 1;
    return i;
  }

  get cropValuesFromStore() {
    return CropVarietyStore.pageValues;
  }

  async searchText(page: number = 1) {
    await CropVarietyStore.getPaginatedCropValue({
      search: this.searchInput,
      page: page
    });
    // CropVarietyStore.getSearchedCropValue(this.searchInput);
    if (this.$route.name) {
      this.$router
        .replace({
          name: this.$route.name,
          query: { page: String(page), search: this.searchInput }
        })
        .catch(error => {});
    }
  }

  private get varieties() {
    return CropVarietyStore.CropValuePaginated;
  }

  editForm(i: number) {
    CropVarietyStore.setValueIndex(i);
    this.$emit("editvarietyvalue", i);
  }

  onDeleteClick(index: number, id: number) {
    let cropVariety = this.varieties.results.filter(x => x.id == id)[0];
    this.$confirm(
      "This will permanently delete the crop variety " +
        cropVariety.name +
        " of crop " +
        cropVariety.croptypename +
        " and all related data. Do you want to continue?",
      "Warning",
      {
        confirmButtonText: "Delete",
        confirmButtonClass: "el-button--danger",
        cancelButtonText: "Cancel",
        showClose: false,
        type: "warning"
      }
    )
      .then(async () => {
        CropVarietyStore.deleteCropVariety({ id: id, index: index });
      })
      .catch(() => {
        this.$message({
          type: "info",
          message: "Delete cancelled"
        });
      });
  }

  private async created() {
    if (this.$route.query.page) {
      this.pageNum = Number(this.$route.query.page);
    }
    this.loading = true;
    if (this.$route.query.search) {
      if (this.$route.query.search != null) {
        this.searchInput = String(this.$route.query.search);
      } else {
        this.searchInput = "";
      }
      await this.searchText(this.pageNum);
    } else {
      await CropVarietyStore.getPaginatedCropValue({
        page: this.pageNum,
        language: this.$i18n.locale
      });
    }
    this.loading = false;
    // CropVarietyStore.getAllCropValue();
  }

  async handlePageChange(val: number) {
    this.pageNum = val;
    // CropVarietyStore.setPageNumber(val);
    this.loading = true;
    await CropVarietyStore.getPaginatedCropValue({
      search: this.searchInput,
      page: val,
      language: this.$i18n.locale
    });
    this.loading = false;
    if (this.$route.name) {
      this.$router
        .replace({
          name: this.$route.name,
          query: { page: String(val), search: this.searchInput }
        })
        .catch(error => {});
    }
  }
}
