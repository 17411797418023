














































































































































































import { Vue, Component, Prop } from "vue-property-decorator";
import { DashboardStore, CropStore, CropVarietyStore, DemandSupplyStore } from "@/store/modules";
import { MetaInfo } from "vue-meta";
import PieChart from "@/views/Admin/Components/Charts/PieChart.vue";
import LineChart from "@/views/Admin/Components/Charts/LineChart.vue";

@Component({
  components: {
    PieChart,
    LineChart
  },
  metaInfo(): MetaInfo {
    return {
      title: "Dashboard",
      meta: [
        { name: "keyword", content: "Sqcc dashboard" },
        {
          name: "description",
          content: "Dashboard of SQCC",
          vmid: "demandList"
        }
      ]
    };
  }
})
export default class SCMList extends Vue {
  searchCompany: string | null = null;
  cropId: number | null = 1;
  cropVarietyId: number | null = 175;
  seedTypeId: number | null = 1;
  supplyDemandTrendTitle: any = { text: "Supply Demand Trend Analysis" };

  get seedCompaniesByProvinceCount() {
    let seedCompaniesByProvinceCount = this.dashboard.seedCompaniesByProvinceCount;
    let data: any = [];
    if (seedCompaniesByProvinceCount != undefined) {
      data = [
        {
          name: "Suppliers",
          colorByPoint: true,
          data: seedCompaniesByProvinceCount.map(x => {
            return {
              name: x.provinceid__name,
              drilldown: x.provinceid__name,
              y: x.count
            };
          })
        }
      ];
    }
    return data;
  }

  get seedCompaniesByDistrictCount() {
    let seedCompaniesByDistrictCount = this.dashboard.seedCompaniesByDistrictCount;
    let seedCompaniesByProvinceCount = this.dashboard.seedCompaniesByProvinceCount;
    let data: any = { series: [] };

    if (seedCompaniesByDistrictCount != undefined && seedCompaniesByProvinceCount != undefined) {
      for (let province of seedCompaniesByProvinceCount) {
        let temp = {
          name: province.provinceid__name,
          id: province.provinceid__name,
          data: seedCompaniesByDistrictCount
            .filter(x => x.provinceid__name == province.provinceid__name)
            .map(x => {
              return [x.districtid__name, x.count];
            })
        };
        data["series"].push(temp);
      }
    }
    return data;
  }

  get demandCompaniesByProvinceCount() {
    let seedCompaniesByProvinceCount = this.dashboard.demandCompaniesByProvinceCount;
    let data: any = [];

    if (seedCompaniesByProvinceCount != undefined) {
      data = [
        {
          name: "Demand Companies",
          colorByPoint: true,
          data: seedCompaniesByProvinceCount.map(x => {
            return {
              name: x.provinceid__name,
              drilldown: x.provinceid__name,
              y: x.count
            };
          })
        }
      ];
    }
    return data;
  }

  get demandCompaniesByDistrictCount() {
    let demandCompaniesByDistrictCount = this.dashboard.demandCompaniesByDistrictCount;
    let demandCompaniesByProvinceCount = this.dashboard.demandCompaniesByProvinceCount;
    let data: any = { series: [] };

    if (
      demandCompaniesByDistrictCount != undefined &&
      demandCompaniesByProvinceCount != undefined
    ) {
      for (let province of demandCompaniesByProvinceCount) {
        let temp = {
          name: province.provinceid__name,
          id: province.provinceid__name,
          data: demandCompaniesByDistrictCount
            .filter(x => x.provinceid__name == province.provinceid__name)
            .map(x => {
              return [x.districtid__name, x.count];
            })
        };
        data["series"].push(temp);
      }
    }
    return data;
  }

  get supplyDemandTrendData() {
    let supplyDemandTrendData = DashboardStore.SupplyDemandTrend;
    let data: any = [];

    if (supplyDemandTrendData != undefined) {
      if (supplyDemandTrendData.demands) {
        data.push({
          name: "Demands",
          data: supplyDemandTrendData.demands.map(x => {
            return {
              name: x.batchid__fiscalyearid__year,
              y: x.sum
            };
          })
        });
      }
      if (supplyDemandTrendData.supplies) {
        data.push({
          name: "Supplies",
          data: supplyDemandTrendData.supplies.map(x => {
            return {
              name: x.batchid__fiscalyearid__year,
              y: x.sum
            };
          })
        });
      }
    }
    return data;
  }

  get supplyDemandTrendXAxis() {
    let supplyDemandTrendData = DashboardStore.SupplyDemandTrend;
    let data: any = {
      title: {
        text: "Fiscal Years"
      }
    };

    if (supplyDemandTrendData != undefined) {
      if (supplyDemandTrendData.demands) {
        data["categories"] = supplyDemandTrendData.demands.map(x => x.batchid__fiscalyearid__year);
      }
    }
    return data;
  }

  get dashboard() {
    return DashboardStore.Dashboard;
  }

  get crops() {
    return CropStore.CropNameList;
  }

  get cropVarieties() {
    let varieties = CropVarietyStore.CropVarieties;
    if (this.cropId && this.cropId !== null) {
      varieties = varieties.filter(x => x.croptypeid == this.cropId);
    } else {
      varieties = [];
    }
    return varieties;
  }

  get seedTypes() {
    return DemandSupplyStore.SeedTypeList;
  }

  get supplyDemandTrendTemporaryTitle() {
    let title = "Supply Demand Trend Analysis";
    if (this.cropVarietyId) {
      let cropVariety = this.cropVarieties.filter(x => x.id == this.cropVarietyId);
      if (cropVariety.length > 0) {
        title += " of " + cropVariety[0].name;
      }
    }
    if (this.seedTypeId) {
      let seedType = this.seedTypes.filter(x => x.id == this.seedTypeId);
      if (seedType.length > 0) {
        title += "'s " + seedType[0].name + " seed";
      }
    }
    return { text: title };
  }

  changeCrop() {
    this.cropVarietyId = null;
    this.seedTypeId = null;
  }

  async getSupplyDemandTrendData() {
    let isValid = await this.$validator.validateAll();
    if (isValid && this.cropVarietyId && this.seedTypeId) {
      DashboardStore.getSupplyDemandTrendData({
        varietyId: this.cropVarietyId,
        seedTypeId: this.seedTypeId
      });
      this.supplyDemandTrendTitle = this.supplyDemandTrendTemporaryTitle;
    }
  }

  private async created() {
    DashboardStore.getDashboardData();
    this.getSupplyDemandTrendData();
    CropStore.getAllCropName();
    CropVarietyStore.getAllCropVariety();
    DemandSupplyStore.getAllSeedType();
  }
}
