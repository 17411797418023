


















































































































































































































import { Vue, Component, Provide } from "vue-property-decorator";
import { CropVariety, CaptionFile } from "@/store/models/cropVariety";
import { CropVarietyStore } from "@/store/modules";
import DynamicField from "@/views/Admin/DynamicField.vue";
import DynamicFieldNepali from "@/views/Admin/DynamicFieldNepali.vue";
import CropVarietyValueList from "@/views/Admin/CropVarietyValueList.vue";
import UploadMultipleImage from "@/views/Admin/UploadMultipleImage.vue";
import { Validator } from "vee-validate";

@Component({
  components: {
    CropVarietyValueList,
    DynamicField,
    DynamicFieldNepali,
    UploadMultipleImage
  }
})
export default class CropVarietyValue extends Vue {
  @Provide("validator") $validator: Validator = this.$validator;
  cropId: number = 0;
  cropvariety = {} as CropVariety;
  selectedIndex = -1;
  dialogVisible: boolean = false;
  loading: boolean = false;
  savingImage: boolean = false;
  saving: boolean = false;
  index: number = 0;
  pageNum: number = 1;
  search: string = "";
  cropvarietyid: number = 0;
  file: File[] = [] as File[];
  captionfile = {
    id: "",
    caption: "",
    nepcaption: "",
    default: false,
    file: [] as any,
    fileList: [] as any,
    url: "",
    name: ""
  } as CaptionFile;
  flag: boolean = false;
  rowindex: number = 0;

  get cropVariety() {
    return CropVarietyStore.CropVariety;
  }

  private async setcropvarietyvalue() {
    let isValid = await this.$validator.validateAll();
    console.log(isValid);
    if (isValid) {
      let rowIndex = this.selectedIndex;
      let error: boolean = false;
      this.saving = true;
      await CropVarietyStore.saveCropVarietyVariableValue({
        obj: await CropVarietyStore.CropVariety,
        index: this.index
      }).catch(reason => {
        error = true;
      });
      this.saving = false;
      if (error) {
        this.$notify.error("Saving Failed");
      } else {
        this.$notify.success("Saved Successfully");
      }
      // this.$router.push({
      //   name: "CropVarietyValueList",
      //   query: { page: String(this.pageNum) }
      // });
    } else {
      this.$notify.error("Invalid Form");
    }
  }

  async submitFiles() {
    let isValid = await this.$validator.validateAll();
    if (isValid) {
      let data = new FormData();
      for (let file of this.file) {
        data.append("image", file);
      }
      if (this.rowindex < 0) {
        data.append("id", "");
      } else {
        data.append("id", this.captionfile.id);
      }
      data.append("caption", this.captionfile.caption);
      data.append("nepcaption", this.captionfile.nepcaption);
      if (this.captionfile.default) {
        data.append("default", String(this.captionfile.default));
      } else {
        data.append("default", String(false));
      }
      data.append("cropvarietiesid", this.cropVariety.id);
      this.savingImage = true;
      await CropVarietyStore.saveCropVarietyImage({
        obj: data,
        index: this.index,
        rowindex: this.rowindex
      }).catch(reason => {
        this.$message.error(reason);
      });
      this.savingImage = false;
      this.dialogVisible = false;
      this.file = [] as File[];
      this.captionfile = {
        id: "",
        caption: "",
        default: false,
        file: [] as any,
        fileList: [] as any,
        url: "",
        name: ""
      } as CaptionFile;
    }
  }

  handleAfterRemove(id: number) {
    // CropVarietyStore.deleteCropVarietyImage(id);
  }

  addnewimage() {
    this.$validator.reset();
    this.captionfile = {} as CaptionFile;
    this.flag = true;
    this.rowindex = -1;
    this.dialogVisible = true;
  }

  editimage(rindex?: any) {
    let imageArray = this.cropVariety.imagearray;
    if (imageArray != undefined) {
      this.$validator.reset();
      this.captionfile = Object.assign({}, imageArray[rindex]);
      this.captionfile.fileList = [];
      this.flag = false;
      this.rowindex = rindex;
      this.dialogVisible = true;
    } else {
      console.log("No Image Array");
    }
  }

  deleteFiles(rindex?: any) {
    if (rindex) {
      let imageArray = this.cropVariety.imagearray;
      if (imageArray != undefined) {
        this.$validator.reset();
        this.captionfile = Object.assign({}, imageArray[rindex]);
        this.captionfile.fileList = [];
        this.flag = false;
        this.rowindex = rindex;
      } else {
        console.log("No Image Array");
      }
    }
    this.$confirm(
      "This will permanently delete the image " +
        this.captionfile.name +
        " and all related data. Do you want to continue?",
      "Warning",
      {
        confirmButtonText: "Delete",
        confirmButtonClass: "el-button--danger",
        cancelButtonText: "Cancel",
        showClose: false,
        type: "warning"
      }
    )
      .then(async () => {
        CropVarietyStore.deleteCropVarietyImage({
          id: this.captionfile.id,
          cropvarietiesid: this.cropVariety.id,
          rowindex: this.rowindex
        });
      })
      .catch(() => {
        this.$message({
          type: "info",
          message: "Delete cancelled"
        });
      });
    this.dialogVisible = false;
  }

  async created() {
    this.pageNum = Number(this.$route.query.page);
    if (this.$route.query.search && this.$route.query.search != null) {
      this.search = String(this.$route.query.search);
    }
    this.cropvarietyid = Number(this.$route.query.cropvarietyid);
    this.loading = true;
    await CropVarietyStore.getCropVarietyById({
      id: this.cropvarietyid,
      language: "en"
    });
    this.loading = false;
  }
}
