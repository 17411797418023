













































import { Vue, Component } from "vue-property-decorator";
import { CropVariety } from "@/store/models/cropVariety";
import { CropVarietyStore, CropStore } from "@/store/modules";
import DynamicField from "@/views/Admin/DynamicField.vue";
import UploadMultipleImage from "@/views/Admin/UploadMultipleImage.vue";
import CropVarietyValueList from "@/views/Admin/CropVarietyValueList.vue";

@Component({
  components: {
    CropVarietyValueList,
    DynamicField,
    UploadMultipleImage
  }
})
export default class SCMList extends Vue {
  username: string = "";
  cropId: number = 0;
  cropvariety = {} as CropVariety;
  selectedIndex = -1;
  dialogVisible: boolean = false;
  index: number = 0;

  private get cropvarietyforadmin() {
    return CropStore.CropNameList;
  }

  async setcropvarietyvalue() {
    console.log(this.cropvariety);
    await CropVarietyStore.saveCropVarietyName({ obj: this.cropvariety });
    this.$notify.success("Saved Successfully");
    let cropId = CropVarietyStore.CropValue[0].id;
    if (cropId != undefined) {
      this.$router
        .push({
          name: "CropVarietyValue",
          query: { cropvarietyid: String(cropId), page: String(1) }
        })
        .catch(error => {});
    }
  }

  private created() {
    CropStore.getAllCropName();
  }
}
