























































































































































import { Vue, Component } from "vue-property-decorator";
import { UserStore, JWTStore, MetaStore } from "@/store/modules";
import { CompanyUser, User } from "@/store/models/user";
import PendingUsers from "@/views/Admin/Components/PendingUsers.vue";
import { eventHub } from "@/utils/eventHub";
import { roleChecker } from "@/utils/jwt";
import DemandAndSupply from "@/store/API/DemandAndSupply";
import { nepaliUnicodeNumber } from "@/store/modules/dateConverter";

@Component({
  components: {
    PendingUsers
  },
  metaInfo: {
    title: "PLO User List",
    meta: [
      { name: "keyword", content: "DOAD user list" },
      {
        name: "description",
        content: "List of users of DOAD",
        vmid: "userList"
      }
    ]
  }
})
export default class PendingUser extends Vue {
  dialogVisible: boolean = false;
  innerVisible: boolean = false;
  loading: boolean = false;
  selectedIndex = -1;
  data: User = {} as User;
  activeName: string = "source_seed_producer";
  searchString: string = "";
  formVisible: any = {
    demandCompany: false,
    seedCompany: false,
    general: false,
    admin: false
  };
  demandCompany: string = "Demand Company";
  demandSupplyCompany: string = "Supply/Demand Company";
  user: CompanyUser = {} as CompanyUser;
  viewerId: any = "";
  selectedProvince: number | null = null;
  pageNum: number = 1;

  roleChecker(roles: string[]) {
    return roleChecker(roles);
  }

  nepaliUnicodeNumber(value: any) {
    if (this.$i18n.locale == "ne" && value) {
      return nepaliUnicodeNumber(value);
    }
    return value;
  }

  indexMethod(index: number) {
    let i = (this.dataList.page - 1) * this.dataList.page_size + index + 1;
    return this.nepaliUnicodeNumber(i);
  }

  get dialogTitle() {
    return `${this.$t("PLO")} ${this.$tc("users", 2)}${this.$tc("'s")} ${this.$tc("detail")}`;
  }

  get dataList() {
    return UserStore.DOADViewer;
  }

  get userDetail() {
    let paginatedViewer = this.dataList;
    let viewers = paginatedViewer.results;
    let viewerDetail = viewers.find(x => x.id == this.viewerId);
    console.log("vd", viewerDetail);
    return viewerDetail;
  }

  get mobileScreen() {
    return MetaStore.mobileScreen;
  }

  get provinces() {
    return MetaStore.Province;
  }

  get districts() {
    let district = MetaStore.District;
    if (this.selectedProvince) {
      district = district.filter(x => x.provinceid == this.selectedProvince);
    } else {
      district = [];
    }
    return district;
  }

  get activeTab() {
    return this.activeName;
  }

  get role() {
    return JWTStore.role;
  }

  get PendingSSPUsers() {
    return UserStore.PendingSSPUsers;
  }

  get PendingISPUsers() {
    return UserStore.PendingISPUsers;
  }

  get PendingISDUsers() {
    return UserStore.PendingISDUsers;
  }

  get PendingISCUsers() {
    return UserStore.PendingISCUsers;
  }

  async viewForm(index: number, id: number) {
    this.dialogVisible = true;
    this.viewerId = id;
    this.userDetail;
  }

  // set activeTab(tab: string) {
  //   this.activeName = tab;
  //   this.$router
  //     .replace({
  //       name: "PendingUsers",
  //       query: { activeTab: this.activeName }
  //     })
  //     .catch((reason: any) => {});
  // }

  // addUser() {
  //   this.$validator.reset();
  //   if (this.activeTab === "source_seed_producer") {
  //     this.$router
  //       .push({
  //         name: "RegisterSourceSeedProducer"
  //       })
  //       .catch((reason: any) => {});
  //   } else if (this.activeTab === "improved_seed_producer") {
  //     this.$router
  //       .push({
  //         name: "RegisterImprovedSeedProducer"
  //       })
  //       .catch((reason: any) => {});
  //   } else if (this.activeTab === "improved_seed_distributor") {
  //     this.$router
  //       .push({
  //         name: "RegisterImprovedSeedDistributor"
  //       })
  //       .catch((reason: any) => {});
  //   } else if (this.activeTab === "improved_seed_consumer") {
  //     this.$router
  //       .push({
  //         name: "RegisterImprovedSeedConsumer"
  //       })
  //       .catch((reason: any) => {});
  //   }
  // }

  addUser() {
    this.$router.push({
      name: "RegisterDOADUser"
    });
  }

  async submit() {
    let isValid = await this.$validator.validateAll();
    if (isValid) {
      this.dialogVisible = false;
    } else {
      this.$message.error("Form  Invalid");
    }
  }

  changeDemandCompanyTabName(obj: string) {
    this.demandCompany = "Demand Company - (" + obj + ")";
    if (obj == undefined) {
      this.demandCompany = "Demand Company";
    }
  }

  changeSupplyDemandCompanyTabName(obj: string) {
    this.demandSupplyCompany = "Supply/Demand Company - (" + obj + ")";
    if (obj == undefined) {
      this.demandSupplyCompany = "Supply/Demand Company";
    }
  }

  private created() {
    UserStore.getPaginatedDOADViewerList();

    MetaStore.getAllProvince();
    MetaStore.getAllDistrict();
    // eventHub.$on("changeDemandCompanyTabName", this.changeDemandCompanyTabName);
    // eventHub.$on("changeSupplyDemandCompanyTabName", this.changeSupplyDemandCompanyTabName);
  }

  editForm(index: any, id: number) {
    this.$router.push({
      name: "EditDOADUser",
      params: {
        id: String(id)
      }
    });
  }

  async handlePrevNextClick(val: any) {
    this.loading = true;
    UserStore.getPaginatedDOADViewerList({
      searchString: this.searchString,
      provinceId: this.selectedProvince,
      page: val
    });
    window.scrollTo({ left: 0, top: 0, behavior: "smooth" });
    this.loading = false;
  }

  searchText() {
    let query = {
      searchString: this.searchString,
      provinceId: this.selectedProvince,
      page: 1
    };
    UserStore.getPaginatedDOADViewerList(query);
  }

  clearFilter() {
    this.selectedProvince = null;
    this.searchString = "";
    UserStore.getPaginatedDOADViewerList();
  }

  clearProvince() {
    this.selectedProvince = null;
  }
}
